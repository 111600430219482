import './Inside.scss';
import SVG from 'react-inlinesvg';

declare let process: {
  env: {
    PUBLIC_URL: string;
  };
};

export default function Inside({ isMobileMode }: { isMobileMode: boolean }) {
  const scope = 'Inside';

  const linesTitle = (
    <h3 className={scope + '-sectionTitle'}>Get to know LIGAOS</h3>
  );
  return (
    <section
      className={`${scope}${isMobileMode ? ` ${scope}--isMobileMode` : ''}`}
    >
      {isMobileMode && linesTitle}
      <div className={scope + '-sectionGrid'}>
        <div className={scope + '-linesColumn'}>
          <div className={scope + '-linesCanvas'}>
            <SVG
              src={`${process.env.PUBLIC_URL}/images/lines.svg`}
              className={scope + '-linesImage'}
            />
            <div className={scope + '-linesNode ' + scope + '-linesNodeTop'}>
              <SVG
                src={`${process.env.PUBLIC_URL}/images/timeboard.svg`}
                className={scope + '-linesLogo'}
              />
            </div>
            <div className={scope + '-linesNode ' + scope + '-linesNodeMiddle'}>
              <SVG
                src={`${process.env.PUBLIC_URL}/images/SAP.svg`}
                className={scope + '-linesLogo'}
              />
            </div>
            <div className={scope + '-linesNode ' + scope + '-linesNodeBottom'}>
              <SVG
                src={`${process.env.PUBLIC_URL}/images/EX3000.svg`}
                className={scope + '-linesLogo'}
              />
            </div>
          </div>
        </div>
        <div>
          {!isMobileMode && linesTitle}
          <p>drei Module - eine Experience</p>
          <ul>
            <li>
              <h4 className={scope + '-listTitle'}>EX3000</h4>
              <p className={scope + '-description'}>
                Das zentrale Tool zur Kalkulation meiner Projekte und Steuerung
                meiner Prozesse.
              </p>
            </li>
            <li>
              <h4 className={scope + '-listTitle'}>Timeboard</h4>
              <p className={scope + '-description'}>
                Zeiterfassung, Zeitcontrolling und Abwesenheitsmanagement so
                einfach und intuitiv wie nur möglich.
              </p>
            </li>
            <li>
              <h4 className={scope + '-listTitle'}>SAP BusinessByDesign</h4>
              <p className={scope + '-description'}>
                Das cloud-basierte ERP System zur Abwicklung der
                rechtsrelevanten Prozesse in Einkauf und Buchhaltung
              </p>
            </li>
          </ul>
          <p className={scope + '-description'}>
            …und alles nahtlos miteinander verbunden über eine mächtige
            Middleware die alle Daten und Prozesse synchronisiert
          </p>
        </div>
      </div>
      <div className={scope + '-bottomPageDescription'}>INSIDE</div>
    </section>
  );
}
