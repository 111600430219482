import './Intro.scss';
// import SVG from 'react-inlinesvg';
// import { useHistory } from 'react-router-dom';

declare let process: {
  env: {
    PUBLIC_URL: string;
    REACT_APP_VERSION: string;
  };
};

export default function Intro({ isMobileMode }: { isMobileMode: boolean }) {
  const scope = 'Intro';
  // const history = useHistory();
  // const goToSignUp = () => history.push('/sign-up');

  const mainImage = (
    <div className={scope + '-ex3000'}>
      <img
        className={scope + '-ex3000Image'}
        src={`${process.env.PUBLIC_URL}/images/EX3000-preview.png?v=${process.env.REACT_APP_VERSION}`}
        alt="EX3000 preview"
      />
    </div>
  );

  return (
    <section
      className={`${scope}${isMobileMode ? ` ${scope}--isMobileMode` : ''}`}
    >
      <div className={scope + '-productsGrid'}>
        <div className={scope + '-leftSection'}>
          <img
            className={scope + '-ligaosAndSap'}
            src={`${process.env.PUBLIC_URL}/images/LigaOSxSAP.svg?v=${process.env.REACT_APP_VERSION}`}
            alt="LigaOSxSAP"
          />
          <h1 className={scope + '-pageHeading'}>
            ERP GEHT
            <br />
            AUCH EINFACH
          </h1>
          <p className={scope + '-pageDescription'}>
            LIGAOS ist unsere neuartige, hybride Unternehmenssoftware, die
            Funktionsvielfalt und Rechtssicherheit von SAP Business ByDesign mit
            einem intuitiven, ästhetischen und smarten User Interface vereint.
          </p>
          {isMobileMode && mainImage}
          <h3 className={scope + '-workingHeader'}>ARBEITEN MIT LIGAOS</h3>
          <ul className={scope + '-pageList'}>
            <li className={scope + '-pageListItem'}>
              Intuitive Nutzeroberfläche - LIGAOS als digitaler Assistent für
              jeden Projektmanager
            </li>
            <li className={scope + '-pageListItem'}>
              Intelligente Architektur - LIGAOS lässt sich nahtlos in Ihre
              Systemlandschaft integrieren
            </li>
            <li className={scope + '-pageListItem'}>
              Flexibilität - LIGAOS passt sich an Ihre Prozesse an und nicht
              umgekehrt
            </li>
            <li className={scope + '-pageListItem'}>
              Exakte Umsatz- und Ertragssteuerung mit zielgerichteten
              Margenvorgaben
            </li>
            <li className={scope + '-pageListItem'}>
              Einfache & effiziente Zeiterfassung per Drag & Drop
            </li>
          </ul>
        </div>
        {!isMobileMode && mainImage}
      </div>
      {/* <button
        type="button"
        className={scope + '-descriptionButton'}
        onClick={goToSignUp}
      >
        <div className={scope + '-descriptionGrid'}>
          <div>
            <SVG
              src={`${process.env.PUBLIC_URL}/images/gin-button.svg`}
              className={scope + '-gin'}
            />
          </div>
          <div>
            <h4 className={scope + '-descriptionTitle'}>
              ERP & GIN TASTING SESSION
              <span className={scope + '-descriptionTitleDate'}>
                | 30. SEPTEMBER 17:00 UHR
              </span>
            </h4>
            <p className={scope + '-descriptionText'}>
              Registrieren Sie sich hier für unser digitales Gin Tasting am
              Donnerstag den 30.09, und lernen Sie in dieser Session unsere neue
              ERP-Lösung LIGAOS kennen!
            </p>
          </div>
          <div className={scope + '-arrow'}>→</div>
        </div>
        {!isMobileMode && (
          <>
            <img
              className={
                scope + '-blurBackground ' + scope + '-blurBackgroundLeft'
              }
              src={`${process.env.PUBLIC_URL}/images/blur-background.png?v=${process.env.REACT_APP_VERSION}`}
            />
            <img
              className={
                scope + '-blurBackground ' + scope + '-blurBackgroundRight'
              }
              src={`${process.env.PUBLIC_URL}/images/blur-background.png?v=${process.env.REACT_APP_VERSION}`}
            />
          </>
        )}
      </button> */}
      <div className={scope + '-bottomPageDescription'}>WELCOME</div>
    </section>
  );
}
