import './CookieBox.scss';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

declare let process: {
  env: {
    REACT_APP_EX3000_WEBSITE_GOOGLE_ANALYTICS_ID: string;
  };
};

export default function CookieBox({ isMobileMode }: { isMobileMode: boolean }) {
  const scope = 'CookieBox';
  const essentialCookies = Cookies.get('privacy-preference-essential');
  const statisticsCookies = Cookies.get('privacy-preference-statistics');

  const [display, setDisplay] = useState(
    !essentialCookies || !statisticsCookies ? '' : 'none',
  );
  const [essentialEnabled, setEssentialEnabled] = useState(
    essentialCookies ? essentialCookies === 'true' : true,
  );
  const [statisticsEnabled, setStatisticsEnabled] = useState(
    statisticsCookies
      ? Cookies.get('privacy-preference-statistics') === 'true'
      : true,
  );

  const acceptSelectedCookies = () => {
    setDisplay('none');
  };

  const updateCookies = () => {
    if (display === 'none') {
      Cookies.set(
        'privacy-preference-essential',
        essentialEnabled ? 'true' : 'false',
      );
      Cookies.set(
        'privacy-preference-statistics',
        statisticsEnabled ? 'true' : 'false',
      );
    }
  };

  useEffect(() => {
    updateCookies();
  });

  const gaID = process.env.REACT_APP_EX3000_WEBSITE_GOOGLE_ANALYTICS_ID;

  return (
    <div className={`${scope}${isMobileMode ? ` ${scope}--isMobileMode` : ''}`}>
      <div className={scope + '-backdrop'} style={{ display }}>
        <section className={scope + '-dialog'}>
          <div className={scope + '-grid'}>
            <div></div>
            <div>
              <h3 className={scope + '-privacyPreference'}>
                Privacy Preference
              </h3>
              <p className={scope + '-cookiesDescription'}>
                We use cookies on our website. Some of them are essential while
                others help us to improve this website and your experience.
              </p>
              <div className={scope + '-checkboxes'}>
                <label className={scope + '-checkbox'}>
                  <input
                    className={scope + '-checkboxInput'}
                    type="checkbox"
                    name="essential"
                    checked={essentialEnabled}
                    onChange={() => setEssentialEnabled(!essentialEnabled)}
                  />
                  Essential
                </label>
                <label className={scope + '-checkbox'}>
                  <input
                    className={scope + '-checkboxInput'}
                    type="checkbox"
                    name="statistics"
                    checked={statisticsEnabled}
                    onChange={() => setStatisticsEnabled(!statisticsEnabled)}
                  />
                  Statistics
                </label>
              </div>
            </div>
            <div>
              <button
                type="button"
                className={scope + '-button'}
                onClick={acceptSelectedCookies}
              >
                Accept
              </button>
            </div>
            <div></div>
          </div>
        </section>
      </div>

      {statisticsEnabled && gaID && (
        <Helmet
          script={[
            {
              type: 'text/javascript',
              innerHTML: 'window.yourObject = { it: "works" }',
            },
          ]}
        >
          {/* Global site tag (gtag.js) - Google Analytics */}
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${gaID}`}
          ></script>
          <script>
            {`
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', '${gaID}');
          `}
          </script>
        </Helmet>
      )}
    </div>
  );
}
