import './Header.scss';
// import { useState } from 'react';
import SVG from 'react-inlinesvg';
import { NavLink } from 'react-router-dom';

declare let process: {
  env: {
    PUBLIC_URL: string;
    REACT_APP_ENV: string;
  };
};

export default function Header({ isMobileMode }: { isMobileMode: boolean }) {
  const scope = 'Header';
  // const [ginTastingHovered, setGinTastingHover] = useState(false);

  return (
    <header
      className={`${scope}${isMobileMode ? ` ${scope}--isMobileMode` : ''}`}
    >
      <NavLink exact to="/" className={scope + '-link'}>
        <SVG
          src={`${process.env.PUBLIC_URL}/images/ligaos.svg`}
          className={scope + '-ligaos'}
        />
      </NavLink>
      <nav>
        <ul className={scope + '-linksList'}>
          <li className={scope + '-linksListItem '}>
            <NavLink
              exact
              to="/"
              className={scope + '-link '}
              activeClassName={scope + '-link--isActive'}
            >
              <span className={scope + '-linkText'}>Home</span>
            </NavLink>
          </li>
          <li className={scope + '-linksListItem '}>
            <NavLink
              exact
              to="/contact"
              className={scope + '-link'}
              activeClassName={scope + '-link--isActive'}
            >
              <span className={scope + '-linkText'}>Contact</span>
            </NavLink>
          </li>
        </ul>
      </nav>
    </header>
  );
}
