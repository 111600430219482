import { ChangeEventHandler } from 'react';
import './Checkbox.scss';

export default function Checkbox(props: {
  name?: string;
  className?: string;
  text?: string;
  checked?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  required?: boolean;
}) {
  const scope = 'Checkbox';
  return (
    <div className={`${scope}${props.className ? ` ${props.className}` : ''}`}>
      <label className={scope + '-label'}>
        <input
          type="checkbox"
          className={scope + '-control'}
          name={props.name}
          onChange={props.onChange}
          checked={props.checked}
          required={props.required}
        />
        <div className={scope + '-checkmark'}></div>
        {`${props.text ? props.text : ''}${props.required ? ` *` : ''}`}
      </label>
    </div>
  );
}
