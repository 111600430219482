import { ChangeEventHandler } from 'react';
import './RadioGroup.scss';

export default function RadioGroup(props: {
  name?: string;
  className?: string;
  value?: string;
  options: {
    label: string;
    value: string;
  }[];
  onChange?: ChangeEventHandler<HTMLInputElement>;
}) {
  const scope = 'RadioGroup';

  return (
    <div className={`${scope}${props.className ? ` ${props.className}` : ''}`}>
      {props.options.map((option) => (
        <label
          key={option.value}
          className={
            scope +
            '-label' +
            (option.value === props.value ? ' ' + scope + '--checked' : '')
          }
        >
          <input
            className={scope + '-input'}
            type="radio"
            name="period"
            value={option.value}
            checked={option.value === props.value}
            onChange={props.onChange}
          />
          {option.label}
        </label>
      ))}
    </div>
  );
}
