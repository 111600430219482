import { MouseEventHandler } from 'react';
import './Button.scss';

export default function Button(props: {
  type?: 'button' | 'submit' | 'reset';
  text?: string;
  className?: string;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}) {
  const scope = 'Button';
  return (
    <div className={`${scope}${props.className ? ` ${props.className}` : ''}`}>
      <button
        className={scope + '-control'}
        type={props.type}
        disabled={props.disabled}
        onClick={props.onClick}
      >
        {props.text}
      </button>
    </div>
  );
}
