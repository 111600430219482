import './NotFound.scss';

export default function NotFound() {
  const scope = 'NotFound';

  return (
    <div className={scope}>
      <h1>404</h1>
      <p>Page Not Found</p>
    </div>
  );
}
