import ContactForm from './components/ContactForm/ContactForm';
import './ContactPage.scss';

export default function ContactPage({
  isMobileMode,
}: {
  isMobileMode: boolean;
}) {
  const scope = 'ContactPage';

  return (
    <div className={`${scope}${isMobileMode ? ` ${scope}--isMobileMode` : ''}`}>
      <h1 className={scope + '-pageTitle'}>Get in touch with us</h1>
      <div>
        <ContactForm />
      </div>
      <div className={scope + '-pageDescriptionGrid'}>
        <div>
          <div>
            <h5 className={scope + '-descriptionTitle'}>LIGADIGITAL AG</h5>
            <p className={scope + '-description'}>
              Herdweg 59
              <br />
              70174 Stuttgart
              <br />
              Deutschland
              <br />
              Telefon +49 711 98690 400
              <br />
              Fax +49 711 98690 499
            </p>
          </div>
        </div>
        <div>
          <div>
            <h5 className={scope + '-descriptionTitle'}>CONTACT</h5>
            <p className={scope + '-description'}>
              General Requests
              <br />
              Laura Servatius
              <br />
              Telefon +49 711 98690 400
              <br />
              info@ligadigital.com
            </p>
          </div>
        </div>
      </div>
      <div className={scope + '-bottomPageDescription'}>CONTACT</div>
    </div>
  );
}
