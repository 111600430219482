import './AppToaster.scss';
import { Markup } from 'interweave';
import toast, { ToastBar, Toaster } from 'react-hot-toast';
import SVG from 'react-inlinesvg';

declare let process: {
  env: {
    PUBLIC_URL: string;
  };
};

export default function AppToaster({
  isMobileMode,
}: {
  isMobileMode: boolean;
}) {
  const scope = 'AppToaster';

  return (
    <div className={`${scope}${isMobileMode ? ` ${scope}--isMobileMode` : ''}`}>
      <Toaster
        toastOptions={{
          duration: 5000,
          style: {
            minWidth: isMobileMode ? '100%' : '500px',
          },
        }}
      >
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <>
                {icon}
                <div className={`${scope}-text`}>
                  <Markup
                    content={
                      // TODO: review it
                      (message as unknown as { props: { children: string } })
                        .props?.children
                    }
                  />
                </div>
                {t.type !== 'loading' && (
                  <button
                    className={scope + '-closeButton'}
                    type="button"
                    onClick={() => toast.dismiss(t.id)}
                  >
                    <SVG
                      className={scope + '-closeToast'}
                      src={`${process.env.PUBLIC_URL}/images/close.svg`}
                    />
                  </button>
                )}
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
    </div>
  );
}
