import './Footer.scss';
import SVG from 'react-inlinesvg';
import { NavLink } from 'react-router-dom';

declare let process: {
  env: {
    PUBLIC_URL: string;
  };
};

export default function Footer({ isMobileMode }: { isMobileMode: boolean }) {
  const scope = 'Footer';

  const brand = (
    <div className={scope + '-brand'}>
      <SVG
        className={scope + '-brandIcon'}
        src={`${process.env.PUBLIC_URL}/images/footer-brand.svg`}
      />
    </div>
  );

  return (
    <footer
      className={`${scope}${isMobileMode ? ` ${scope}--isMobileMode` : ''}`}
    >
      <div className={scope + '-contactUs'}>
        <NavLink exact to="/contact" className={scope + '-contactUsLink'}>
          Contact us
        </NavLink>
      </div>
      {!isMobileMode ? <div></div> : brand}
      <div className={scope + '-bottomGrid'}>
        <ul className={scope + '-linksList'}>
          <li className={scope + '-linksListItem'}>
            <NavLink
              exact
              to="/imprint"
              className={scope + '-link'}
              activeClassName={scope + '-link--isActive'}
            >
              Imprint
            </NavLink>
          </li>
          <li className={scope + '-linksListItem'}>
            <NavLink
              exact
              to="/privacy-policy"
              className={scope + '-link'}
              activeClassName={scope + '-link--isActive'}
            >
              Privacy
            </NavLink>
          </li>
        </ul>
        {isMobileMode ? <div></div> : brand}
        <ul className={scope + '-socialList'}>
          <li className={scope + '-socialListItem'}>
            <a
              href="https://twitter.com/ligadigital_ag"
              target="_blank"
              rel="noreferrer"
              className={scope + '-socialListItemLink'}
            >
              <SVG
                className={scope + '-socialListItemImage'}
                src={`${process.env.PUBLIC_URL}/images/twitter.svg`}
              />
            </a>
          </li>
          <li className={scope + '-socialListItem'}>
            <a
              href="https://github.com/ligadigital"
              target="_blank"
              rel="noreferrer"
              className={scope + '-socialListItemLink'}
            >
              <SVG
                className={scope + '-socialListItemImage'}
                src={`${process.env.PUBLIC_URL}/images/github.svg`}
              />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}
