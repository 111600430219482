import './Imprint.scss';

export default function Imprint({ isMobileMode }: { isMobileMode: boolean }) {
  const scope = 'Imprint';

  return (
    <div className={`${scope}${isMobileMode ? ` ${scope}--isMobileMode` : ''}`}>
      <h1 className={scope + '-pageTitle'}>Imprint</h1>
      <h4 className={scope + '-descriptionTitle'}>
        INFORMATION PURSUANT TO § 5 TMG
      </h4>
      <p className={scope + '-description'}>
        LIGADIGITAL AG
        <br />
        Herdweg 59
        <br />
        70174 Stuttgart
      </p>
      <h4 className={scope + '-descriptionTitle'}>
        COMMERCIAL REGISTER: HRB 20427
      </h4>
      <p className={scope + '-description'}>
        Registration court: Amtsgericht Stuttgart
      </p>
      <h4 className={scope + '-descriptionTitle'}>REPRESENTED BY:</h4>
      <p className={scope + '-description'}>
        Julian Servatius
        <br />
        Matthias Gölz
      </p>
      <h4 className={scope + '-descriptionTitle'}>
        CHAIRMAN OF THE SUPERVISORY BOARD:
      </h4>
      <p className={scope + '-description'}>Vincent Bodo Andrin</p>
      <h4 className={scope + '-descriptionTitle'}>CONTACT</h4>
      <p className={scope + '-description'}>
        Telefon: +49 711 98 690-400
        <br />
        Telefax: +49 711 98 690-499
        <br />
        E-Mail: info@ligadigital.com
      </p>
      <h4 className={scope + '-descriptionTitle'}>VAT ID</h4>
      <p className={scope + '-description'}>
        Sales tax identification number according to § 27 a of the Sales Tax
        Law: <br />
        DE203041937
      </p>

      <h4 className={scope + '-descriptionTitle'}>EU DISPUTE RESOLUTION</h4>
      <p className={scope + '-description'}>
        We are not willing or obliged to participate in dispute resolution
        proceedings before a consumer arbitration board.
      </p>

      <h4 className={scope + '-descriptionTitle'}>LIABILITY FOR CONTENTS</h4>
      <p className={scope + '-description'}>
        As service providers, we are liable for own contents of these websites
        according to Paragraph 7, Sect. 1 German Telemedia Act (TMG). However,
        according to Paragraphs 8 to 10 German Telemedia Act (TMG), service
        providers are not obligated to permanently monitor submitted or stored
        information or to search for evidences that indicate illegal activities.
        <br />
        Legal obligations to removing information or to blocking the use of
        information remain unchallenged. In this case, liability is only
        possible at the time of knowledge about a specific violation of law.
        Illegal contents will be removed immediately at the time we get
        knowledge of them.
      </p>

      <h4 className={scope + '-descriptionTitle'}>LIABILITY FOR LINKS</h4>
      <p className={scope + '-description'}>
        Our offer includes links to external third party websites. We have no
        influence on the contents of those websites, therefore we cannot
        guarantee for those contents. Providers or administrators of linked
        websites are always responsible for their own contents. <br />
        The linked websites had been checked for possible violations of law at
        the time of the establishment of the link. Illegal contents were not
        detected at the time of the linking. A permanent monitoring of the
        contents of linked websites cannot be imposed without reasonable
        indications that there has been a violation of law. Illegal links will
        be removed immediately at the time we get knowledge of them.
      </p>

      <h4 className={scope + '-descriptionTitle'}>COPYRIGHT</h4>
      <p className={scope + '-description'}>
        Contents and compilations published on these websites by the providers
        are subject to German copyright laws. Reproduction, editing,
        distribution as well as the use of any kind outside the scope of the
        copyright law require a written permission of the author or originator.
        Downloads and copies of these websites are prohibited. The commercial
        use of our contents without permission of the originator is prohibited.
        <br />
        Copyright laws of third parties are respected as long as the contents on
        these websites do not originate from the provider. Contributions of
        third parties on this site are indicated as such. However, if you notice
        any violations of copyright law, please inform us. Such contents will be
        removed immediately.
      </p>
      <div className={scope + '-bottomPageDescription'}>IMPRINT</div>
    </div>
  );
}
