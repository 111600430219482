import SVG from 'react-inlinesvg';
import './Origin.scss';

declare let process: {
  env: {
    PUBLIC_URL: string;
    REACT_APP_VERSION: string;
  };
};

export default function Origin({ isMobileMode }: { isMobileMode: boolean }) {
  const scope = 'Origin';

  const problemImage = (
    <div className={scope + '-problemBox'}>
      <SVG
        src={`${process.env.PUBLIC_URL}/images/problem.svg`}
        className={scope + '-problemImage'}
      />
      <img
        className={
          scope + '-blurBackground ' + scope + '-blurBackgroundProblem'
        }
        src={`${process.env.PUBLIC_URL}/images/blur-background.png?v=${process.env.REACT_APP_VERSION}`}
      />
    </div>
  );

  return (
    <section
      className={`${scope}${isMobileMode ? ` ${scope}--isMobileMode` : ''}`}
    >
      <div className={scope + '-problemGrid'}>
        <div>{!isMobileMode && problemImage}</div>
        <div>
          <h4 className={scope + '-problemTitle'}>The Problem</h4>
          <p className={scope + '-imageDescription'}>
            Wir waren auf der Suche nach einem operativen Betriebssystem, dass
            sämtliche wertschöpfende Prozesse digitalisieren und verknüpfen
            sollte und dabei sowohl unseren Ansprüchen an Ästhetik und
            Performance, sowie den Anforderungen unserer Stakeholder an
            Reporting und Rechtssicherheit gerecht werden sollte.
            <br />
            <strong className={scope + '-importantDescription'}>
              Gefunden haben wir nichts.
            </strong>
          </p>
          {isMobileMode && problemImage}
        </div>
      </div>

      <div className={scope + '-visionGrid'}>
        <div className={scope + '-visionColumn'}>
          <h4 className={scope + '-visionTitle'}>The Vision</h4>
          <p className={scope + '-imageDescription'}>
            Eine hybride Unternehmenssoftware für alle projektorientierten
            Agenturen, Dienstleister und Händler.
          </p>
        </div>
        <div>
          <div className={scope + '-visionBox'}>
            <SVG
              src={`${process.env.PUBLIC_URL}/images/vision.svg`}
              className={scope + '-visionImage'}
            />
            <img
              className={
                scope + '-blurBackground ' + scope + '-blurBackgroundVision'
              }
              src={`${process.env.PUBLIC_URL}/images/blur-background.png?v=${process.env.REACT_APP_VERSION}`}
            />
          </div>
        </div>
      </div>

      <div className={scope + '-solution'}>
        <h4 className={scope + '-solutionTitle'}>The Solution</h4>
        <p className={scope + '-solutionText'}>
          Wir kombinieren SAP BusinessByDesign als cloud basiertes,
          rechtssicheres Backend mit einer modernen, intuitiven und flexiblen
          Nutzeroberfläche und schaffen so eine ganz neue Art von
          Unternehmenssoftware.
        </p>
        <div className={scope + '-arrow'}>→</div>
      </div>
      <div className={scope + '-bottomPageDescription'}>ORIGINS</div>
    </section>
  );
}
