import { ChangeEvent, useState } from 'react';
import SVG from 'react-inlinesvg';
import { useHistory } from 'react-router-dom';
import Button from '../../../../components/Button/Button';
import RadioGroup from '../../../../components/RadioGroup/RadioGroup';
import './Pricing.scss';

declare let process: {
  env: {
    PUBLIC_URL: string;
    REACT_APP_VERSION: string;
  };
};

export default function Pricing({ isMobileMode }: { isMobileMode: boolean }) {
  const scope = 'Pricing';
  const [period, setPeriod] = useState('monthly');
  const history = useHistory();
  const goToContact = () => history.push('/contact');

  return (
    <section
      className={`${scope}${isMobileMode ? ` ${scope}--isMobileMode` : ''}`}
    >
      <div className={scope + '-sectionGrid'}>
        <div>
          <h2 className={scope + '-sectionTitle'}>Pricing</h2>
          <h4 className={scope + '-descriptionTitle'}>All-in</h4>
          <p className={scope + '-description'}>
            In unseren Lizenzpreisen sind alle vorgestellten Komponenten (EX3000
            & Timeboard) sowie die komplette SAP Business ByDesign Lösung
            enthalten. Außerdem natürlich Hosting, Updates und Support.
          </p>
          <h4 className={scope + '-descriptionTitle'}>
            Geringe Implementierungskosten
          </h4>
          <p className={scope + '-description'}>
            Durch unsere moderne Architektur und das selbsterklärende User
            Interface können wir unsere Lösung mit einem überraschend geringen
            Implementierungs- und Schulungsaufwand bei Ihnen einführen.
          </p>
        </div>
        <div>
          <RadioGroup
            name="period"
            className={scope + '-period'}
            value={period}
            options={[
              {
                label: 'MONTHLY',
                value: 'monthly',
              },
              {
                label: 'YEARLY',
                value: 'yearly',
              },
            ]}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setPeriod(event.target.value);
            }}
          />
          <section className={scope + '-priceSection'}>
            <img
              className={scope + '-ligaosAndSap'}
              src={`${process.env.PUBLIC_URL}/images/LigaOSxSAP.svg?v=${process.env.REACT_APP_VERSION}`}
              alt="LigaOSxSAP"
            />
            <p className={scope + '-priceSectionDescription'}>
              Durchschnittlicher Userpreis bei einer
              <br /> Abnahme von 75 Lizenzen
            </p>
            <p className={scope + '-priceText'}>
              <span className={scope + '-prefix'}>€</span>
              <span className={scope + '-price'}>
                {period === 'monthly' ? 79 : 948}
              </span>
              <span className={scope + '-priceDescription'}>
                {' '}
                pro user {period === 'monthly' && ' & monat'}
              </span>
            </p>
            <ul className={scope + '-priceList'}>
              <li className={scope + '-priceListItem'}>
                <SVG
                  src={`${process.env.PUBLIC_URL}/images/checkmark.svg`}
                  className={scope + '-checkmark'}
                />
                <div>
                  <strong>EX3000</strong> – Projektmanagement Suite
                </div>
              </li>
              <li className={scope + '-priceListItem'}>
                <SVG
                  src={`${process.env.PUBLIC_URL}/images/checkmark.svg`}
                  className={scope + '-checkmark'}
                />
                <div>
                  <strong>Timeboard</strong> – Zeiterfassung, Kapazitäten und
                  Abwesenheiten
                </div>
              </li>
              <li className={scope + '-priceListItem'}>
                <SVG
                  src={`${process.env.PUBLIC_URL}/images/checkmark.svg`}
                  className={scope + '-checkmark'}
                />
                <div>
                  <strong>SAP Busines ByDesign</strong> – inklusive aller Module
                </div>
              </li>
            </ul>
          </section>
          <Button
            type="button"
            text="GET IN TOUCH"
            className={scope + '-getInTouch'}
            onClick={goToContact}
          />
        </div>
      </div>

      <div className={scope + '-bottomPageDescription'}>PRICING</div>
    </section>
  );
}
