import './Textarea.scss';

export default function Textarea(props: {
  name?: string;
  placeholder?: string;
  className?: string;
  required?: boolean;
}) {
  const scope = 'Textarea';
  return (
    <div className={`${scope}${props.className ? ` ${props.className}` : ''}`}>
      <textarea
        className={scope + '-control'}
        name={props.name}
        placeholder={`${props.placeholder ? props.placeholder : ''}${
          props.required ? ` *` : ''
        }`}
        required={props.required}
      ></textarea>
    </div>
  );
}
