import './AboutUs.scss';
import SVG from 'react-inlinesvg';

declare let process: {
  env: {
    PUBLIC_URL: string;
    REACT_APP_VERSION: string;
  };
};

export default function AboutUs({ isMobileMode }: { isMobileMode: boolean }) {
  const scope = 'AboutUs';

  return (
    <section
      className={`${scope}${isMobileMode ? ` ${scope}--isMobileMode` : ''}`}
    >
      <h2 className={scope + '-sectionTitle'}>About us</h2>
      <p className={scope + '-sectionDescription'}>
        <strong>LIGADIGITAL</strong> ist die digitale Einheit von LIGANOVA, den
        Experten für außergewöhnliche und kreative Konzepte am POS. Wir bauen
        Software, die den User in den Mittelpunkt stellt und gleichzeitig
        komplexe Prozesse auf erstaunlich einfache Weise abbildet.
      </p>
      <p className={scope + '-sectionDescription'}>
        Mit <strong>all4cloud</strong> haben wir uns pragmatische
        Prozessversteher an Bord geholt, die es sich zur Mission gemacht haben,
        Unternehmen mit integrierten Prozesslandschaften zu mehr Transparenz und
        Geschwindigkeit zu verhelfen - weit über simples Projektmanagement
        hinaus.
      </p>

      <div className={scope + '-teamGrid'}>
        <section className={scope + '-teamSection'}>
          <img
            className={scope + '-teamImage'}
            src={`${process.env.PUBLIC_URL}/images/henrik-hausen.png?v=${process.env.REACT_APP_VERSION}`}
            alt="Henrik Hausen"
          />
          <h5 className={scope + '-teamName'}>Henrik Hausen</h5>
          <p className={scope + '-description'}>- CEO all4cloud -</p>
          <p className={scope + '-quote'}>
            <SVG
              src={`${process.env.PUBLIC_URL}/images/quotes.svg`}
              className={scope + '-quotes'}
            />
            LIGAOS und SAP BusinessByDesign ergänzen sich optimal für unsere
            Kunden aus dem Agentur-, Dienstleistungsbereich sowie dem Handel.
          </p>
          <img
            className={scope + '-all4Cloud'}
            src={`${process.env.PUBLIC_URL}/images/all-4-cloud.svg?v=${process.env.REACT_APP_VERSION}`}
            alt="All4Cloud"
          />
        </section>

        <section className={scope + '-teamSection'}>
          <img
            className={scope + '-teamImage'}
            src={`${process.env.PUBLIC_URL}/images/matthias-golz.png?v=${process.env.REACT_APP_VERSION}`}
            alt="Matthias Gölz"
          />
          <h5 className={scope + '-teamName'}>Matthias Gölz</h5>
          <p className={scope + '-description'}>- Vorstand LIGADIGITAL AG -</p>
          <p className={scope + '-quote'}>
            <SVG
              src={`${process.env.PUBLIC_URL}/images/quotes.svg`}
              className={scope + '-quotes'}
            />
            Mit unserem Ökosystem verzahnen und harmonisieren wir die Auftrags-
            und Produktionsdenkweise von SAP mit einem projektzentrischen
            Geschäftsmodell.
          </p>
          <img
            className={scope + '-ligadigital'}
            src={`${process.env.PUBLIC_URL}/images/ligadigital.svg?v=${process.env.REACT_APP_VERSION}`}
            alt="Ligadigital"
          />
        </section>

        <section className={scope + '-teamSection'}>
          <img
            className={scope + '-teamImage'}
            src={`${process.env.PUBLIC_URL}/images/vincent-bodo-andrin.png?v=${process.env.REACT_APP_VERSION}`}
            alt="Vincent Bodo Andrin"
          />
          <h5 className={scope + '-teamName'}>Vincent Bodo Andrin</h5>
          <p className={scope + '-description'}>- Founder - LIGANOVA GmbH -</p>
          <p className={scope + '-quote'}>
            <SVG
              src={`${process.env.PUBLIC_URL}/images/quotes.svg`}
              className={scope + '-quotes'}
            />
            Wir haben ein individuelles, intelligentes, intuitives und humanes
            Betriebssystem für die ertragsorientierte Steuerung von
            Dienstleistungsunternehmen geschaffen.
          </p>
          <img
            className={scope + '-liganova'}
            src={`${process.env.PUBLIC_URL}/images/liganova.svg?v=${process.env.REACT_APP_VERSION}`}
            alt="Liganova"
          />
        </section>
      </div>

      <div className={scope + '-bottomPageDescription'}>ABOUT US</div>
    </section>
  );
}
