import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppToaster from './components/AppToaster/AppToaster';
import CookieBox from './components/CookieBox/CookieBox';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Main from './components/Main/Main';
import { isMobileMode as _isMobileMode } from './helpers/isMobileMode';

export default function App() {
  const [isMobileMode, setIsMobileMode] = useState(_isMobileMode());
  useEffect(() => {
    function handleResize() {
      setIsMobileMode(_isMobileMode());
    }

    window.addEventListener('resize', handleResize);
    return () => {
      document.removeEventListener('resize', handleResize);
    };
  });

  return (
    <BrowserRouter>
      <Header isMobileMode={isMobileMode} />
      <Main isMobileMode={isMobileMode} />
      <Footer isMobileMode={isMobileMode} />
      <AppToaster isMobileMode={isMobileMode} />
      <CookieBox isMobileMode={isMobileMode} />
    </BrowserRouter>
  );
}
