import './PrivacyPolicy.scss';

export default function PrivacyPolicy({
  isMobileMode,
}: {
  isMobileMode: boolean;
}) {
  const scope = 'PrivacyPolicy';
  return (
    <div className={`${scope}${isMobileMode ? ` ${scope}--isMobileMode` : ''}`}>
      <h1 className={scope + '-pageTitle'}>Privacy Policy</h1>
      <h3 className={scope + '-sectionTitle'}>
        1. AN OVERVIEW OF DATA PROTECTION
      </h3>
      <h4 className={scope + '-descriptionTitle'}>GENERAL</h4>
      <p className={scope + '-description'}>
        The following gives a simple overview of what happens to your personal
        information when you visit our website. Personal information is any data
        with which you could be personally identified. Detailed information on
        the subject of data protection can be found in our privacy policy found
        below.
      </p>
      <h4 className={scope + '-descriptionTitle'}>
        DATA COLLECTION ON OUR WEBSITE
      </h4>
      <p className={scope + '-description'}>
        Who is responsible for the data collection on this website? The data
        collected on this website are processed by the website operator. The
        operator&apos;s contact details can be found in the website&apos;s
        required legal notice. How do we collect your data? Some data are
        collected when you provide it to us. This could, for example, be data
        you enter on a contact form. Other data are collected automatically by
        our IT systems when you visit the website. These data are primarily
        technical data such as the browser and operating system you are using or
        when you accessed the page. These data are collected automatically as
        soon as you enter our website. What do we use your data for? Part of the
        data is collected to ensure the proper functioning of the website. Other
        data can be used to analyze how visitors use the site. What rights do
        you have regarding your data? You always have the right to request
        information about your stored data, its origin, its recipients, and the
        purpose of its collection at no charge. You also have the right to
        request that it be corrected, blocked, or deleted. You can contact us at
        any time using the address given in the legal notice if you have further
        questions about the issue of privacy and data protection. You may also,
        of course, file a complaint with the competent regulatory authorities.
      </p>
      <h4 className={scope + '-descriptionTitle'}>
        ANALYTICS AND THIRD-PARTY TOOLS
      </h4>
      <p className={scope + '-description'}>
        When visiting our website, statistical analyses may be made of your
        surfing behavior. This happens primarily using cookies and analytics.
        The analysis of your surfing behavior is usually anonymous, i.e. we will
        not be able to identify you from this data. You can object to this
        analysis or prevent it by not using certain tools. Detailed information
        can be found in the following privacy policy. You can object to this
        analysis. We will inform you below about how to exercise your options in
        this regard.
      </p>
      <h3 className={scope + '-sectionTitle'}>
        2. GENERAL INFORMATION AND MANDATORY INFORMATION
      </h3>
      <h4 className={scope + '-descriptionTitle'}>DATA PROTECTION</h4>
      <p className={scope + '-description'}>
        The operators of this website take the protection of your personal data
        very seriously. We treat your personal data as confidential and in
        accordance with the statutory data protection regulations and this
        privacy policy. If you use this website, various pieces of personal data
        will be collected. Personal information is any data with which you could
        be personally identified. This privacy policy explains what information
        we collect and what we use it for. It also explains how and for what
        purpose this happens. Please note that data transmitted via the internet
        (e.g. via email communication) may be subject to security breaches.
        Complete protection of your data from third-party access is not
        possible.
      </p>
      <h4 className={scope + '-descriptionTitle'}>
        NOTICE CONCERNING THE PARTY RESPONSIBLE FOR THIS WEBSITE
      </h4>
      <p className={scope + '-description'}>
        The party responsible for processing data on this website is:
        <br />
        LIGADIGITAL AG
        <br />
        Julian Servatius / Matthias Gölz
        <br />
        Herdweg 59
        <br />
        70174 Stuttgart <br />
        Telephone: +49 711 98690 400 <br />
        Email: info@ligadigital.com <br />
        The responsible party is the natural or legal person who alone or
        jointly with others decides on the purposes and means of processing
        personal data (names, email addresses, etc.).
      </p>

      <h4 className={scope + '-descriptionTitle'}>
        REVOCATION OF YOUR CONSENT TO THE PROCESSING OF YOUR DATA
      </h4>
      <p className={scope + '-description'}>
        Many data processing operations are only possible with your express
        consent. You may revoke your consent at any time with future effect. An
        informal email making this request is sufficient. The data processed
        before we receive your request may still be legally processed.
      </p>
      <h4 className={scope + '-descriptionTitle'}>
        RIGHT TO FILE COMPLAINTS WITH REGULATORY AUTHORITIES
      </h4>
      <p className={scope + '-description'}>
        If there has been a breach of data protection legislation, the person
        affected may file a complaint with the competent regulatory authorities.
        The competent regulatory authority for matters related to data
        protection legislation is the data protection officer of the German
        state in which our company is headquartered. A list of data protection
        officers and their contact details can be found at the following link:
        https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.
      </p>
      <h4 className={scope + '-descriptionTitle'}>RIGHT TO DATA PORTABILITY</h4>
      <p className={scope + '-description'}>
        You have the right to have data which we process based on your consent
        or in fulfillment of a contract automatically delivered to yourself or
        to a third party in a standard, machine-readable format. If you require
        the direct transfer of data to another responsible party, this will only
        be done to the extent technically feasible.
      </p>
      <h4 className={scope + '-descriptionTitle'}>SSL OR TLS ENCRYPTION</h4>
      <p className={scope + '-description'}>
        This site uses SSL or TLS encryption for security reasons and for the
        protection of the transmission of confidential content, such as the
        inquiries you send to us as the site operator. You can recognize an
        encrypted connection in your browser&apos;s address line when it changes
        from &quot;http://&quot; to &quot;https://&quot; and the lock icon is
        displayed in your browser&apos;s address bar. If SSL or TLS encryption
        is activated, the data you transfer to us cannot be read by third
        parties.
      </p>
      <h4 className={scope + '-descriptionTitle'}>
        INFORMATION, BLOCKING, DELETION
      </h4>
      <p className={scope + '-description'}>
        As permitted by law, you have the right to be provided at any time with
        information free of charge about any of your personal data that is
        stored as well as its origin, the recipient and the purpose for which it
        has been processed. You also have the right to have this data corrected,
        blocked or deleted. You can contact us at any time using the address
        given in our legal notice if you have further questions on the topic of
        personal data.
      </p>
      <h4 className={scope + '-descriptionTitle'}>
        OPPOSITION TO PROMOTIONAL EMAILS
      </h4>
      <p className={scope + '-description'}>
        We hereby expressly prohibit the use of contact data published in the
        context of website legal notice requirements with regard to sending
        promotional and informational materials not expressly requested. The
        website operator reserves the right to take specific legal action if
        unsolicited advertising material, such as email spam, is received.
      </p>

      <h3 className={scope + '-sectionTitle'}>3. DATA PROTECTION OFFICER</h3>

      <h4 className={scope + '-descriptionTitle'}>
        STATUTORY DATA PROTECTION OFFICER
      </h4>
      <p className={scope + '-description'}>
        We have appointed a data protection officer for our company.
        <br />
        ZEITGEIST Group
        <br />
        Timo Schönauer
        <br />
        Herdweg 59
        <br />
        70174 Stuttgart
        <br />
        Telephone: +49 711 652 20 0<br />
        Email: datenschutz@zeitgeist.group
      </p>
      <h3 className={scope + '-sectionTitle'}>
        4. DATA COLLECTION ON OUR WEBSITE
      </h3>
      <h4 className={scope + '-descriptionTitle'}>COOKIES</h4>
      <p className={scope + '-description'}>
        Some of our web pages use cookies. Cookies do not harm your computer and
        do not contain any viruses. Cookies help make our website more
        user-friendly, efficient, and secure. Cookies are small text files that
        are stored on your computer and saved by your browser. Most of the
        cookies we use are so-called &quot;session cookies.&quot; They are
        automatically deleted after your visit. Other cookies remain in your
        device&apos;s memory until you delete them. These cookies make it
        possible to recognize your browser when you next visit the site. You can
        configure your browser to inform you about the use of cookies so that
        you can decide on a case-by-case basis whether to accept or reject a
        cookie. Alternatively, your browser can be configured to automatically
        accept cookies under certain conditions or to always reject them, or to
        automatically delete cookies when closing your browser. Disabling
        cookies may limit the functionality of this website. Cookies which are
        necessary to allow electronic communications or to provide certain
        functions you wish to use (such as the shopping cart) are stored
        pursuant to Art. 6 paragraph 1, letter f of DSGVO. The website operator
        has a legitimate interest in the storage of cookies to ensure an
        optimized service provided free of technical errors. If other cookies
        (such as those used to analyze your surfing behavior) are also stored,
        they will be treated separately in this privacy policy.
      </p>
      <h4 className={scope + '-descriptionTitle'}>SERVER LOG FILES</h4>
      <p className={scope + '-description'}>
        The website provider automatically collects and stores information that
        your browser automatically transmits to us in &quot;server log
        files&quot;. These are:
        <br />
        - Browser type and browser version
        <br />
        - Operating system used
        <br />
        - Referrer URL
        <br />
        - Host name of the accessing computer
        <br />
        - Time of the server request
        <br />- IP address
        <br />
        These data will not be combined with data from other sources. The basis
        for data processing is Art. 6 (1) (f) DSGVO, which allows the processing
        of data to fulfill a contract or for measures preliminary to a contract.
      </p>
      <h4 className={scope + '-descriptionTitle'}>CONTACT FORM</h4>
      <p className={scope + '-description'}>
        Should you send us questions via the contact form, we will collect the
        data entered on the form, including the contact details you provide, to
        answer your question and any follow-up questions. We do not share this
        information without your permission. We will, therefore, process any
        data you enter onto the contact form only with your consent per Art. 6
        (1)(a) DSGVO. You may revoke your consent at any time. An informal email
        making this request is sufficient. The data processed before we receive
        your request may still be legally processed. We will retain the data you
        provide on the contact form until you request its deletion, revoke your
        consent for its storage, or the purpose for its storage no longer
        pertains (e.g. after fulfilling your request). Any mandatory statutory
        provisions, especially those regarding mandatory data retention periods,
        remain unaffected by this provision.
      </p>
      <h4 className={scope + '-descriptionTitle'}>
        DATENSCHUTZHINWEISE IM BEWERBUNGSVERFAHREN
      </h4>
      <p className={scope + '-description'}>
        Wir verarbeiten die Bewerberdaten nur zum Zweck und im Rahmen des
        Bewerbungsverfahrens im Einklang mit den gesetzlichen Vorgaben. Die
        Verarbeitung der Bewerberdaten erfolgt zur Erfüllung unserer
        (vor)vertraglichen Verpflichtungen im Rahmen des Bewerbungsverfahrens im
        Sinne des Art. 6 Abs. 1 lit. b. DSGVO Art. 6 Abs. 1 lit. f. DSGVO sofern
        die Datenverarbeitung z.B. im Rahmen von rechtlichen Verfahren für uns
        erforderlich wird (in Deutschland gilt zusätzlich § 26 BDSG). Das
        Bewerbungsverfahren setzt voraus, dass Bewerber uns die Bewerberdaten
        mitteilen. Die notwendigen Bewerberdaten sind, sofern wir ein
        Onlineformular anbieten gekennzeichnet, ergeben sich sonst aus den
        Stellenbeschreibungen und grundsätzlich gehören dazu die Angaben zur
        Person, Post- und Kontaktadressen und die zur Bewerbung gehörenden
        Unterlagen, wie Anschreiben, Lebenslauf und die Zeugnisse. Daneben
        können uns Bewerber freiwillig zusätzliche Informationen mitteilen. Mit
        der Übermittlung der Bewerbung an uns, erklären sich die Bewerber mit
        der Verarbeitung ihrer Daten zu Zwecken des Bewerbungsverfahrens
        entsprechend der in dieser Datenschutzerklärung dargelegten Art und
        Umfang einverstanden. Soweit im Rahmen des Bewerbungsverfahrens
        freiwillig besondere Kategorien von personenbezogenen Daten im Sinne des
        Art. 9 Abs. 1 DSGVO mitgeteilt werden, erfolgt deren Verarbeitung
        zusätzlich nach Art. 9 Abs. 2 lit. b DSGVO (z.B. Gesundheitsdaten, wie
        z.B. Schwerbehinderteneigenschaft oder ethnische Herkunft). Soweit im
        Rahmen des Bewerbungsverfahrens besondere Kategorien von
        personenbezogenen Daten im Sinne des Art. 9 Abs. 1 DSGVO bei Bewerbern
        angefragt werden, erfolgt deren Verarbeitung zusätzlich nach Art. 9 Abs.
        2 lit. a DSGVO (z.B. Gesundheitsdaten, wenn diese für die Berufsausübung
        erforderlich sind). Sofern zur Verfügung gestellt, können uns Bewerber
        ihre Bewerbungen mittels eines Onlineformulars auf unserer Website
        übermitteln. Die Daten werden entsprechend dem Stand der Technik
        verschlüsselt an uns übertragen. Ferner können Bewerber uns ihre
        Bewerbungen via E-Mail übermitteln. Hierbei bitten wir jedoch zu
        beachten, dass E-Mails grundsätzlich nicht verschlüsselt versendet
        werden und die Bewerber selbst für die Verschlüsselung sorgen müssen.
        Wir können daher für den Übertragungsweg der Bewerbung zwischen dem
        Absender und dem Empfang auf unserem Server keine Verantwortung
        übernehmen und empfehlen daher eher ein Online-Formular oder den
        postalischen Versand zu nutzen. Denn statt der Bewerbung über das
        Online-Formular und E-Mail, steht den Bewerbern weiterhin die
        Möglichkeit zur Verfügung, uns die Bewerbung auf dem Postweg zuzusenden.
        Die von den Bewerbern zur Verfügung gestellten Daten, können im Fall
        einer erfolgreichen Bewerbung für die Zwecke des
        Beschäftigungsverhältnisses von uns weiterverarbeitet werden.
        Andernfalls, sofern die Bewerbung auf ein Stellenangebot nicht
        erfolgreich ist, werden die Daten der Bewerber gelöscht. Die Daten der
        Bewerber werden ebenfalls gelöscht, wenn eine Bewerbung zurückgezogen
        wird, wozu die Bewerber jederzeit berechtigt sind. Die Löschung erfolgt,
        vorbehaltlich eines berechtigten Widerrufs der Bewerber, nach dem Ablauf
        eines Zeitraums von sechs Monaten, damit wir etwaige Anschlussfragen zu
        der Bewerbung beantworten und unseren Nachweispflichten aus dem
        Gleichbehandlungsgesetz genügen können. Rechnungen über etwaige
        Reisekostenerstattung werden entsprechend den steuerrechtlichen Vorgaben
        archiviert.
      </p>
      <h4 className={scope + '-descriptionTitle'}>TALENT-POOL</h4>
      <p className={scope + '-description'}>
        Im Rahmen der Bewerbung bieten wir den Bewerbern die Möglichkeit an, in
        unseren „Talent-Pool“ für einen Zeitraum von zwei Jahren auf Grundlage
        einer Einwilligung im Sinne der Art. 6 Abs. 1 lit. b. und Art. 7 DSGVO
        aufgenommen zu werden. Die Bewerbungsunterlagen im Talent-Pool werden
        alleine im Rahmen von künftigen Stellenausschreibungen und der
        Beschäftigtensuche verarbeitet und werden spätestens nach Ablauf der
        Frist vernichtet. Die Bewerber werden darüber belehrt, dass deren
        Einwilligung in die Aufnahme in den Talent-Pool freiwillig ist, keinen
        Einfluss auf das aktuelle Bewerbungsverfahren hat und sie diese
        Einwilligung jederzeit für die Zukunft widerrufen sowie Widerspruch im
        Sinne des Art. 21 DSGVO erklären können.
      </p>

      <h3 className={scope + '-sectionTitle'}>5. SOCIAL MEDIA</h3>

      <h4 className={scope + '-descriptionTitle'}>
        FACEBOOK PLUGINS (LIKE & SHARE BUTTONS)
      </h4>
      <p className={scope + '-description'}>
        Our website includes plugins for the social network Facebook, Facebook
        Inc., 1 Hacker Way, Menlo Park, California 94025, USA. The Facebook
        plugins can be recognized by the Facebook logo or the Like button on our
        site. For an overview of Facebook plugins, see
        https://developers.facebook.com/docs/plugins/. When you visit our site,
        a direct connection between your browser and the Facebook server is
        established via the plugin. This enables Facebook to receive information
        that you have visited our site from your IP address. If you click on the
        Facebook &quot;Like button&quot; while you are logged into your Facebook
        account, you can link the content of our site to your Facebook profile.
        This allows Facebook to associate visits to our site with your user
        account. Please note that, as the operator of this site, we have no
        knowledge of the content of the data transmitted to Facebook or of how
        Facebook uses these data. For more information, please see
        Facebook&apos;s privacy policy at https://de-de.facebook.com/policy.php.
        If you do not want Facebook to associate your visit to our site with
        your Facebook account, please log out of your Facebook account.
      </p>
      <h4 className={scope + '-descriptionTitle'}>TWITTER PLUGIN</h4>
      <p className={scope + '-description'}>
        Functions of the Twitter service have been integrated into our website
        and app. These features are offered by Twitter Inc., 1355 Market Street,
        Suite 900, San Francisco, CA 94103, USA. When you use Twitter and the
        “Retweet” function, the websites you visit are connected to your Twitter
        account and made known to other users. In doing so, data will also be
        transferred to Twitter. We would like to point out that, as the provider
        of these pages, we have no knowledge of the content of the data
        transmitted or how it will be used by Twitter. For more information on
        Twitter&apos;s privacy policy, please go to https://twitter.com/privacy.
        Your privacy preferences with Twitter can be modified in your account
        settings at https://twitter.com/account/settings.
      </p>
      <h4 className={scope + '-descriptionTitle'}>GOOGLE+ PLUGIN</h4>
      <p className={scope + '-description'}>
        Our pages use Google+ functions. It is operated by Google Inc., 1600
        Amphitheatre Parkway, Mountain View, CA 94043, USA. Collection and
        disclosure of information: Using the Google +1 button allows you to
        publish information worldwide. By means of the Google+ button, you and
        other users can receive custom content from Google and our partners.
        Google stores both the fact that you have +1&apos;d a piece of content
        and information about the page you were viewing when you clicked +1.
        Your +1 can be displayed together with your profile name and photo in
        Google services, for example in search results or in your Google
        profile, or in other places on websites and advertisements on the
        Internet. Google records information about your +1 activities to improve
        Google services for you and others. To use the Google + button, you need
        a globally visible, public Google profile that must contain at least the
        name chosen for the profile. This name is used by all Google services.
        In some cases, this name may also replace a different name that you have
        used to share content via your Google account. The identity of your
        Google profile can be shown to users who know your email address or
        other information that can identify you. Use of collected data: In
        addition to the uses mentioned above, the information you provide is
        used in accordance with the applicable Google data protection policies.
        Google may publish summary statistics about users&apos; +1 activity or
        share it with users and partners, such as publishers, advertisers, or
        affiliate websites.
      </p>
      <h4 className={scope + '-descriptionTitle'}>INSTAGRAM PLUGIN</h4>
      <p className={scope + '-description'}>
        Our website contains functions of the Instagram service. These functions
        are offered by Instagram Inc., 1601 Willow Road, Menlo Park, CA 94025,
        USA. If you are logged into your Instagram account, you can click the
        Instagram button to link the content of our pages with your Instagram
        profile. This means that Instagram can associate visits to our pages
        with your user account. As the provider of this website, we expressly
        point out that we receive no information on the content of the
        transmitted data or its use by Instagram. For more information, see the
        Instagram Privacy Policy: https://instagram.com/about/legal/privacy/.
      </p>
      <h4 className={scope + '-descriptionTitle'}>LINKEDIN PLUGIN</h4>
      <p className={scope + '-description'}>
        Our site uses functions from the LinkedIn network. The service is
        provided by LinkedIn Corporation, 2029 Stierlin Court, Mountain View, CA
        94043, USA. Each time one of our pages containing LinkedIn features is
        accessed, your browser establishes a direct connection to the LinkedIn
        servers. LinkedIn is informed that you have visited our web pages from
        your IP address. If you use the LinkedIn “Recommend” button and are
        logged into your LinkedIn account, it is possible for LinkedIn to
        associate your visit to our website to your user account. We would like
        to point out that, as the provider of these pages, we have no knowledge
        of the content of the data transmitted or how it will be used by
        LinkedIn. More information can be found in the LinkedIn privacy policy
        at https://www.linkedin.com/legal/privacy-policy.
      </p>
      <h4 className={scope + '-descriptionTitle'}>XING PLUGIN</h4>
      <p className={scope + '-description'}>
        Our website uses features provided by the XING network. The provider is
        XING AG, Dammtorstraße 29-32, 20354 Hamburg, Germany. Each time one of
        our pages containing XING features is accessed, your browser establishes
        a direct connection to the XING servers. To the best of our knowledge,
        no personal data is stored in the process. In particular, no IP
        addresses are stored nor is usage behavior evaluated. For more
        information about data protection and the XING Share button, please see
        the XING privacy policy at
        https://www.xing.com/app/share?op=data_protection.
      </p>
      <h3 className={scope + '-sectionTitle'}>6. ANALYTICS AND ADVERTISING</h3>
      <h4 className={scope + '-descriptionTitle'}>GOOGLE ANALYTICS</h4>
      <p className={scope + '-description'}>
        This website uses Google Analytics, a web analytics service. It is
        operated by Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA
        94043, USA. Google Analytics uses so-called &quot;cookies&quot;. These
        are text files that are stored on your computer and that allow an
        analysis of the use of the website by you. The information generated by
        the cookie about your use of this website is usually transmitted to a
        Google server in the USA and stored there. Google Analytics cookies are
        stored based on Art. 6 (1) (f) DSGVO. The website operator has a
        legitimate interest in analyzing user behavior to optimize both its
        website and its advertising.
        <span className={scope + '-smallHeading'}>IP anonymization</span>
        We have activated the IP anonymization feature on this website. Your IP
        address will be shortened by Google within the European Union or other
        parties to the Agreement on the European Economic Area prior to
        transmission to the United States. Only in exceptional cases is the full
        IP address sent to a Google server in the US and shortened there. Google
        will use this information on behalf of the operator of this website to
        evaluate your use of the website, to compile reports on website
        activity, and to provide other services regarding website activity and
        Internet usage for the website operator. The IP address transmitted by
        your browser as part of Google Analytics will not be merged with any
        other data held by Google.
        <span className={scope + '-smallHeading'}>Browser plugin</span>
        You can prevent these cookies being stored by selecting the appropriate
        settings in your browser. However, we wish to point out that doing so
        may mean you will not be able to enjoy the full functionality of this
        website. You can also prevent the data generated by cookies about your
        use of the website (incl. your IP address) from being passed to Google,
        and the processing of these data by Google, by downloading and
        installing the browser plugin available at the following link:
        https://tools.google.com/dlpage/gaoptout?hl=en.
        <span className={scope + '-smallHeading'}>
          Objecting to the collection of data
        </span>
        You can prevent the collection of your data by Google Analytics by
        clicking on the following link. An opt-out cookie will be set to prevent
        your data from being collected on future visits to this site: Disable
        Google Analytics. For more information about how Google Analytics
        handles user data, see Google&apos;s privacy policy:
        https://support.google.com/analytics/answer/6004245?hl=en.
        <span className={scope + '-smallHeading'}>
          Outsourced data processing
        </span>
        We have entered into an agreement with Google for the outsourcing of our
        data processing and fully implement the strict requirements of the
        German data protection authorities when using Google Analytics.
        <span className={scope + '-smallHeading'}>
          Demographic data collection by Google Analytics
        </span>
        This website uses Google Analytics&apos; demographic features. This
        allows reports to be generated containing statements about the age,
        gender, and interests of site visitors. This data comes from
        interest-based advertising from Google and third-party visitor data.
        This collected data cannot be attributed to any specific individual
        person. You can disable this feature at any time by adjusting the ads
        settings in your Google account or you can forbid the collection of your
        data by Google Analytics as described in the section &quot;Refusal of
        data collection&quot;.
      </p>
      <h4 className={scope + '-descriptionTitle'}>
        GOOGLE ADWORDS AND GOOGLE CONVERSION TRACKING
      </h4>
      <p className={scope + '-description'}>
        This website uses Google AdWords. AdWords is an online advertising
        program from Google Inc., 1600 Amphitheater Parkway, Mountain View, CA
        94043, United States (&quot;Google&quot;). As part of Google AdWords, we
        use so-called conversion tracking. When you click on an ad served by
        Google, a conversion tracking cookie is set. Cookies are small text
        files that your internet browser stores on your computer. These cookies
        expire after 30 days and are not used for personal identification of the
        user. Should the user visit certain pages of the website and the cookie
        has not yet expired, Google and the website can tell that the user
        clicked on the ad and proceeded to that page. Each Google AdWords
        advertiser has a different cookie. Thus, cookies cannot be tracked using
        the website of an AdWords advertiser. The information obtained using the
        conversion cookie is used to create conversion statistics for the
        AdWords advertisers who have opted for conversion tracking. Customers
        are told the total number of users who clicked on their ad and were
        redirected to a conversion tracking tag page. However, advertisers do
        not obtain any information that can be used to personally identify
        users. If you do not want to participate in tracking, you can opt-out of
        this by easily disabling the Google Conversion Tracking cookie by
        changing your browser settings. In doing so, you will not be included in
        the conversion tracking statistics. Conversion cookies are stored based
        on Art. 6 (1) (f) DSGVO. The website operator has a legitimate interest
        in analyzing user behavior to optimize both its website and its
        advertising. For more information about Google AdWords and Google
        Conversion Tracking, see the Google Privacy Policy:
        https://www.google.de/policies/privacy/. You can configure your browser
        to inform you about the use of cookies so that you can decide on a
        case-by-case basis whether to accept or reject a cookie. Alternatively,
        your browser can be configured to automatically accept cookies under
        certain conditions or to always reject them, or to automatically delete
        cookies when closing your browser. Disabling cookies may limit the
        functionality of this website.
      </p>
      <h4 className={scope + '-descriptionTitle'}>GOOGLE RECAPTCHA</h4>
      <p className={scope + '-description'}>
        We use &quot;Google reCAPTCHA&quot; (hereinafter &quot;reCAPTCHA&quot;)
        on our websites. This service is provided by Google Inc., 1600
        Amphitheater Parkway, Mountain View, CA 94043, USA (&quot;Google&quot;).
        reCAPTCHA is used to check whether the data entered on our website (such
        as on a contact form) has been entered by a human or by an automated
        program. To do this, reCAPTCHA analyzes the behavior of the website
        visitor based on various characteristics. This analysis starts
        automatically as soon as the website visitor enters the website. For the
        analysis, reCAPTCHA evaluates various information (e.g. IP address, how
        long the visitor has been on the website, or mouse movements made by the
        user). The data collected during the analysis will be forwarded to
        Google. The reCAPTCHA analyses take place completely in the background.
        Website visitors are not advised that such an analysis is taking place.
        Data processing is based on Art. 6 (1) (f) DSGVO. The website operator
        has a legitimate interest in protecting its site from abusive automated
        crawling and spam. For more information about Google reCAPTCHA and
        Google&apos;s privacy policy, please visit the following links:
        https://www.google.com/intl/de/policies/privacy/ and
        https://www.google.com/recaptcha/intro/android.html.
      </p>

      <h3 className={scope + '-sectionTitle'}>7. NEWSLETTER</h3>

      <h4 className={scope + '-descriptionTitle'}>NEWSLETTER DATA</h4>
      <p className={scope + '-description'}>
        If you would like to receive our newsletter, we require a valid email
        address as well as information that allows us to verify that you are the
        owner of the specified email address and that you agree to receive this
        newsletter. No additional data is collected or is only collected on a
        voluntary basis. We only use this data to send the requested information
        and do not pass it on to third parties. We will, therefore, process any
        data you enter onto the contact form only with your consent per Art. 6
        (1) (a) DSGVO. You can revoke consent to the storage of your data and
        email address as well as their use for sending the newsletter at any
        time, e.g. through the &quot;unsubscribe&quot; link in the newsletter.
        The data processed before we receive your request may still be legally
        processed. The data provided when registering for the newsletter will be
        used to distribute the newsletter until you cancel your subscription
        when said data will be deleted. Data we have stored for other purposes
        (e.g. email addresses for the members area) remain unaffected.
      </p>
      <h3 className={scope + '-sectionTitle'}>8. PLUGINS AND TOOLS</h3>

      <h4 className={scope + '-descriptionTitle'}>YOUTUBE</h4>
      <p className={scope + '-description'}>
        Our website uses plugins from YouTube, which is operated by Google. The
        operator of the pages is YouTube LLC, 901 Cherry Ave., San Bruno, CA
        94066, USA. If you visit one of our pages featuring a YouTube plugin, a
        connection to the YouTube servers is established. Here the YouTube
        server is informed about which of our pages you have visited. If
        you&apos;re logged in to your YouTube account, YouTube allows you to
        associate your browsing behavior directly with your personal profile.
        You can prevent this by logging out of your YouTube account. YouTube is
        used to help make our website appealing. This constitutes a justified
        interest pursuant to Art. 6 (1) (f) DSGVO. Further information about
        handling user data, can be found in the data protection declaration of
        YouTube under https://www.google.de/intl/de/policies/privacy.
      </p>
      <h4 className={scope + '-descriptionTitle'}>VIMEO</h4>
      <p className={scope + '-description'}>
        Our website uses features provided by the Vimeo video portal. This
        service is provided by Vimeo Inc., 555 West 18th Street, New York, New
        York 10011, USA. If you visit one of our pages featuring a Vimeo plugin,
        a connection to the Vimeo servers is established. Here the Vimeo server
        is informed about which of our pages you have visited. In addition,
        Vimeo will receive your IP address. This also applies if you are not
        logged in to Vimeo when you visit our website or do not have a Vimeo
        account. The information is transmitted to a Vimeo server in the US,
        where it is stored. If you are logged in to your Vimeo account, Vimeo
        allows you to associate your browsing behavior directly with your
        personal profile. You can prevent this by logging out of your Vimeo
        account. For more information on how to handle user data, please refer
        to the Vimeo Privacy Policy at https://vimeo.com/privacy.
      </p>
      <h4 className={scope + '-descriptionTitle'}>GOOGLE WEB FONTS</h4>
      <p className={scope + '-description'}>
        For uniform representation of fonts, this page uses web fonts provided
        by Google. When you open a page, your browser loads the required web
        fonts into your browser cache to display texts and fonts correctly. For
        this purpose your browser has to establish a direct connection to Google
        servers. Google thus becomes aware that our web page was accessed via
        your IP address. The use of Google Web fonts is done in the interest of
        a uniform and attractive presentation of our website. This constitutes a
        justified interest pursuant to Art. 6 (1) (f) DSGVO. If your browser
        does not support web fonts, a standard font is used by your computer.
        Further information about handling user data, can be found at
        https://developers.google.com/fonts/faq and in Google&apos;s privacy
        policy at https://www.google.com/policies/privacy/.
      </p>
      <h4 className={scope + '-descriptionTitle'}>GOOGLE MAPS</h4>
      <p className={scope + '-description'}>
        This site uses the Google Maps map service via an API. It is operated by
        Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. To
        use Google Maps, it is necessary to save your IP address. This
        information is generally transmitted to a Google server in the USA and
        stored there. The provider of this site has no influence on this data
        transfer. The use of Google Maps is in the interest of making our
        website appealing and to facilitate the location of places specified by
        us on the website. This constitutes a justified interest pursuant to
        Art. 6 (1) (f) DSGVO. Further information about handling user data, can
        be found in the data protection declaration of Google at
        https://www.google.de/intl/de/policies/privacy/.
      </p>
      <h4 className={scope + '-descriptionTitle'}>SOUNDCLOUD</h4>
      <p className={scope + '-description'}>
        On our pages, plugins of the SoundCloud social network (SoundCloud
        Limited, Berners House, 47-48 Berners Street, London W1T 3NF, UK) may be
        integrated. The SoundCloud plugins can be recognized by the SoundCloud
        logo on our site. When you visit our site, a direct connection between
        your browser and the SoundCloud server is established via the plugin.
        This enables SoundCloud to receive information that you have visited our
        site from your IP address. If you click on the &quot;Like&quot; or
        &quot;Share&quot; buttons while you are logged into your SoundCloud
        account, you can link the content of our pages to your SoundCloud
        profile. This means that SoundCloud can associate visits to our pages
        with your user account. We would like to point out that, as the provider
        of these pages, we have no knowledge of the content of the data
        transmitted or how it will be used by SoundCloud. For more information
        on SoundCloud&apos;s privacy policy, please go to
        https://soundcloud.com/pages/privacy. If you do not want SoundCloud to
        associate your visit to our site with your SoundCloud account, please
        log out of your SoundCloud account.
      </p>
      <div className={scope + '-bottomPageDescription'}>IMPRINT</div>
    </div>
  );
}
