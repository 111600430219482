import './ContactForm.scss';
import { FormEvent } from 'react';
import toast from 'react-hot-toast';
import Button from '../../../../components/Button/Button';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import Input from '../../../../components/Input/Input';
import Textarea from '../../../../components/Textarea/Textarea';
import { DEFAULT_ERROR_MESSAGE } from '../../../../constants/config.constant';

declare let process: {
  env: {
    REACT_APP_EX3000_WEBSITE_API_URL: string;
  };
};

export default function ContactForm() {
  const scope = 'ContactForm';

  return (
    <form className={scope} onSubmit={onSubmit}>
      <div className={scope + '-controls'}>
        <Input
          className={scope + '-name'}
          name={'name'}
          placeholder={'Name'}
          required={true}
        />
        <Input
          className={scope + '-firma'}
          name={'firma'}
          placeholder={'Firma'}
        />
        <Input
          className={scope + '-email'}
          name={'email'}
          placeholder={'E-Mail'}
          required={true}
        />
        <Input
          className={scope + '-phone'}
          name={'phone'}
          placeholder={'Telefonnummer'}
        />
        <Textarea
          className={scope + '-message'}
          name={'message'}
          placeholder={'Nachricht'}
        />
      </div>
      <Checkbox
        className={scope + '-confirmCheckbox'}
        text="Ich stimme zu, dass meine Angaben aus dem Kontaktformular zur Beantwortung meiner Anfrage erhoben und verarbeitet werden. Die Daten werden nach abgeschlossener Bearbeitung Ihrer Anfrage gelöscht. Hinweis: Sie können Ihre Einwilligung jederzeit für die Zukunft per E-Mail an info@ligadigital.com widerrufen. Detaillierte Informationen zum Umgang mit Nutzerdaten finden Sie in unserer Datenschutzerklärung."
        name="confirmCheckbox"
        required={true}
      />
      <p className={scope + '-sendDescription'}>Let’s get things moving</p>
      <Button type="submit" text="Send" className={scope + '-submit'} />
    </form>
  );
}

function onSubmit(event: FormEvent<HTMLFormElement>) {
  event.preventDefault();
  const formElement = event.target as HTMLFormElement;
  const fetchPromise = fetch(
    `${process.env.REACT_APP_EX3000_WEBSITE_API_URL}contact-form.php`,
    {
      method: 'POST',
      body: new FormData(formElement),
    },
  );

  return fetchPromise
    .then((response) => {
      return response
        .json()
        .then(
          ({
            result,
            errorMessage,
          }: {
            result?: boolean;
            errorMessage?: string;
          }) => {
            if (errorMessage) {
              toast.error(errorMessage);
            } else if (result) {
              formElement.reset();
              toast.success(
                `<h5>CHEERS!</h5>
                  <p>Your message was sent.</p>`,
              );
            }
          },
        );
    })
    .catch(() => {
      toast.error(DEFAULT_ERROR_MESSAGE);
    });
}
