import './TimeBoard.scss';

declare let process: {
  env: {
    PUBLIC_URL: string;
    REACT_APP_VERSION: string;
  };
};

export default function TimeBoard({ isMobileMode }: { isMobileMode: boolean }) {
  const scope = 'TimeBoard';

  const timeBoardImage = (
    <img
      className={scope + '-timeBoardImage'}
      src={`${process.env.PUBLIC_URL}/images/time-board.png?v=${process.env.REACT_APP_VERSION}`}
      alt="Timeboard"
    />
  );

  return (
    <section
      className={`${scope}${isMobileMode ? ` ${scope}--isMobileMode` : ''}`}
    >
      <h2 className={scope + '-sectionTitle'}>Timeboard</h2>

      <p className={scope + '-sectionDescription'}>
        Unser integriertes Timeboard macht Zeiterfassung, Zeitcontrolling und
        Abwesenheitsmanagement so einfach und intuitiv wie nur möglich.
      </p>

      <div className={scope + '-timeBoardGrid'}>
        <div>{!isMobileMode && timeBoardImage}</div>
        <div className={scope + '-timeBoardInfoColumn'}>
          <h4 className={scope + '-timeBoardTitle'}>
            Zeiterfassung ganz einfach
          </h4>
          <ul>
            <li>
              <h5 className={scope + '-listTitle'}>DRAG & DROP</h5>
              <p className={scope + '-description'}>
                Die Projektarbeitszeiten lassen sich ganz einfach per Drag &
                Drop erfassen.
              </p>
            </li>
            <li>
              <h5 className={scope + '-listTitle'}>
                TIMEBOARD KENNT DEINE PROJEKTE
              </h5>
              <p className={scope + '-description'}>
                Das Timeboard erkennt, auf welche Projekte häufig gebucht wird
                und stellt diese intelligent als Favoriten zur Verfügung
              </p>
            </li>
            <li>
              <h5 className={scope + '-listTitle'}>VERKNÜPFUNG ZUM KALENDER</h5>
              <p className={scope + '-description'}>
                Termine im Kalender können über die Projektnummer automatisch in
                Zeiteinträge umgewandelt werden.
              </p>
            </li>
          </ul>
          {isMobileMode && timeBoardImage}
        </div>
      </div>

      <div className={scope + '-timeBoardGrid'}>
        <div>
          <h4 className={scope + '-abstractionTitle'}>Abwesenheiten</h4>
          <p className={scope + '-bottomDescription'}>
            Du brauchst Urlaub oder bist krank…
            <br /> Vom Request bis zur Freigabe.
          </p>
          <img
            className={scope + '-holidaysImage'}
            src={`${process.env.PUBLIC_URL}/images/holidays.png?v=${process.env.REACT_APP_VERSION}`}
            alt="Holidays"
          />
        </div>
        <div>
          <h4 className={scope + '-timeControllingTitle'}>Zeitcontrolling</h4>
          <p className={scope + '-bottomDescription'}>
            Dein Team, eure Kapazitäten. Alles im Überblick.
          </p>
          <img
            className={scope + '-teamOverviewImage'}
            src={`${process.env.PUBLIC_URL}/images/team-overview.png?v=${process.env.REACT_APP_VERSION}`}
            alt="Holidays"
          />
        </div>
      </div>

      <div className={scope + '-bottomPageDescription'}>Timeboard</div>
    </section>
  );
}
