import './Main.scss';
import { Route, Switch } from 'react-router-dom';
import ContactPage from '../../pages/Contact/ContactPage';
import Imprint from '../../pages/Imprint/Imprint';
import NotFound from '../../pages/NotFound/NotFound';
import PrivacyPolicy from '../../pages/PrivacyPolicy/PrivacyPolicy';
// import SignUp from '../../pages/SignUp/SignUp';
import WhyLigaOS from '../../pages/WhyLigaOS/WhyLigaOS';
import ScrollToTop from '../ScrollToTop/ScrollToTop';

export default function Main({ isMobileMode }: { isMobileMode: boolean }) {
  const scope = 'Main';

  return (
    <main className={scope}>
      <ScrollToTop />
      <Switch>
        <Route exact path="/">
          <WhyLigaOS isMobileMode={isMobileMode} />
        </Route>
        <Route exact path="/imprint">
          <Imprint isMobileMode={isMobileMode} />
        </Route>
        <Route exact path="/privacy-policy">
          <PrivacyPolicy isMobileMode={isMobileMode} />
        </Route>
        <Route exact path="/contact">
          <ContactPage isMobileMode={isMobileMode} />
        </Route>
        {/* <Route exact path="/sign-up">
          <SignUp isMobileMode={isMobileMode} />
        </Route> */}
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </main>
  );
}
