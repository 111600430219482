export function isMobileMode(): boolean {
  const isIOS = /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent);
  const isAndroid = /(?:Android)/.test(navigator.userAgent);
  const mobileMaxWidth = 768;
  return (
    isIOS ||
    isAndroid ||
    window.matchMedia(`(max-width: ${mobileMaxWidth}px)`).matches
  );
}
