import AboutUs from './components/AboutUs/AboutUs';
import EX3000 from './components/EX3000/EX3000';
import Inside from './components/Inside/Inside';
import Intro from './components/Intro/Intro';
import Origin from './components/Origin/Origin';
import Pricing from './components/Pricing/Pricing';
import TimeBoard from './components/TimeBoard/TimeBoard';

export default function WhyLigaOS({ isMobileMode }: { isMobileMode: boolean }) {
  return (
    <div>
      <Intro isMobileMode={isMobileMode}></Intro>
      <Origin isMobileMode={isMobileMode}></Origin>
      <Inside isMobileMode={isMobileMode}></Inside>
      <EX3000 isMobileMode={isMobileMode}></EX3000>
      <TimeBoard isMobileMode={isMobileMode}></TimeBoard>
      <AboutUs isMobileMode={isMobileMode}></AboutUs>
      <Pricing isMobileMode={isMobileMode}></Pricing>
    </div>
  );
}
