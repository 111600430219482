import './EX3000.scss';

declare let process: {
  env: {
    PUBLIC_URL: string;
    REACT_APP_VERSION: string;
  };
};

export default function EX3000({ isMobileMode }: { isMobileMode: boolean }) {
  const scope = 'EX3000';

  const projectOverviewImage = (
    <img
      className={scope + '-projectOverviewImage'}
      src={`${process.env.PUBLIC_URL}/images/project-overview.png?v=${process.env.REACT_APP_VERSION}`}
      alt="Project overview"
    />
  );

  const staffingImage = (
    <img
      className={scope + '-staffingImage'}
      src={`${process.env.PUBLIC_URL}/images/staffing.png?v=${process.env.REACT_APP_VERSION}`}
      alt="Staffing"
    />
  );

  return (
    <section
      className={`${scope}${isMobileMode ? ` ${scope}--isMobileMode` : ''}`}
    >
      <h2 className={scope + '-sectionTitle'}>EX3000</h2>
      <p className={scope + '-sectionDescription'}>
        Das zentrale Tool zur Kalkulation meiner Projekte und Steuerung meiner
        Prozesse.
      </p>

      <div className={scope + '-projectOverviewGrid'}>
        <div>{!isMobileMode && projectOverviewImage}</div>
        <div className={scope + '-projectOverviewInfoColumn'}>
          <h4 className={scope + '-projectOverviewTitle'}>
            Projekte machen Spaß
          </h4>
          <p>
            Von der Projektanlage über die Kostenschätzung, der Projektplanung,
            dem Einkauf, der Kalkulation bis hin zur Abrechnung und eventuellen
            Versand.
          </p>
          <p className={scope + '-projectOverviewDescription'}>
            EX3000 agiert als intelligenter, digitaler Assistent und führt den
            Projektmanger über seine selbst erklärende Oberfläche zu einem
            erfolgreichen Projekt.
          </p>
          {isMobileMode && projectOverviewImage}
        </div>
      </div>

      <div className={scope + '-calculationGrid'}>
        <div>
          <h4 className={scope + '-calculationTitle'}>
            Ertrag gezielt steuern
          </h4>
          <p className={scope + '-calculationSubTitle'}>
            Mit dem integrierten „Targeting“ kann EX3000 mit enormer Genauigkeit
            steuern welchen Ertrag man erzielen möchte!
          </p>
          <ul>
            <li>
              <h5 className={scope + '-listTitle'}>TARGET PRICING</h5>
              <p className={scope + '-calculationDescription'}>
                Pro Kunde und Art der Leistung können unterschiedliche
                Zielmargen definiert werden - EX3000 hilft dem Projektmanager
                dann, diese auch zu erreichen.
              </p>
            </li>
            <li>
              <h5 className={scope + '-listTitle'}>VALIDATION & APPROVAL</h5>
              <p className={scope + '-calculationDescription'}>
                Validierung von EKs, VKs sowie der Möglichkeit Freigabeflows für
                Kalkulation und Angebote einzurichten.
              </p>
            </li>
          </ul>
        </div>
        <div>
          <img
            className={scope + '-calculationImage'}
            src={`${process.env.PUBLIC_URL}/images/calculation.png?v=${process.env.REACT_APP_VERSION}`}
            alt="Calculation"
          />
        </div>
      </div>

      <div className={scope + '-staffingGrid'}>
        <div>{!isMobileMode && staffingImage}</div>
        <div>
          <h4 className={scope + '-staffingTitle'}>Time is money</h4>
          <p>
            Die integrierte Zeiterfassung ermöglicht eine einfache und
            effiziente Projektsteuerung.
          </p>
          <ul>
            <li>
              <h5 className={scope + '-listTitle'}>
                INTELLIGENTE RESSOURCENPLANUNG
              </h5>
              <p className={scope + '-calculationDescription'}>
                Der Projektplan erstellt sich automatisiert aus den Positionen
                der Kalkulation und garantiert dadurch effizientes arbeiten
              </p>
            </li>
            <li>
              <h5 className={scope + '-listTitle'}>VOLLE KONTROLLE</h5>
              <p className={scope + '-calculationDescription'}>
                Die erfassten Arbeitszeiten der Mitarbeiter sind direkt im
                Projektplan ersichtlich
              </p>
            </li>
          </ul>
          {isMobileMode && staffingImage}
        </div>
      </div>

      <div className={scope + '-bottomPageDescription'}>EX3000</div>
    </section>
  );
}
